
import SiteSidebar from '~/layouts/partials/public/sidebar'
import SiteSocial from '~/layouts/partials/public/social'

export default {
  components:{
      SiteSidebar,
      SiteSocial
  },
  jsonld() {
    return {
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "Organization",
          "@id": `${process.env.BASE_URL}/#organization`,
          "name": `${this.$config.siteName}`,
          "url": process.env.BASE_URL,
          "email": "support@webtoolsmate.com",
          "logo":{
            "@type": "ImageObject",
            "@id": `${process.env.BASE_URL}/#logo`,
            "url": `${process.env.BASE_URL}/assets/images/webtoolsmate-logo.png`,
            "contentUrl": `${process.env.BASE_URL}/assets/images/webtoolsmate-logo.png`,
            "caption": `${this.$config.siteName}`,
            "inLanguage": "en-US",
            "width": "512",
            "height": "512"
          },
          "sameAs": this.$config.socialLinks 
        },
        {
          "@type":"WebSite",
          "url":`${process.env.BASE_URL}`,
          "name":`${this.$config.siteName}`,
          "inLanguage":"en-US",
          "publisher":{
            "@id": `${process.env.BASE_URL}/#organization`
          },
          "potentialAction":{
            "@type":"SearchAction",
            "target": `${process.env.BASE_URL}/?s={search_term_string}`,
            "query-input":"required name=search_term_string"
          }
        }
      ]
    };
  },
  head() {
    return {
      title: 'WebToolsMate: Free Online Web Tools',
      meta: [
        { hid: 'title', name: 'title', content: 'WebToolsMate: Free Online Web Tools' },
        { hid: 'description', name: 'description', content: 'WebToolsMate provides the best 100% Free Online Web Tools on the Internet that can be useful for your daily online activities.' },
        // Facebook meta settings
        { hid: 'og:site_name', property: 'og:site_name', content: `${this.$config.siteName}` },
        { hid: 'og:email', property: 'og:email', content: 'support@webtoolsmate.com' },
        { hid: 'og:title', property: 'og:title', content: 'WebToolsMate: Free Online Web Tools' },
        { hid: 'og:description', property: 'og:description', content: 'WebToolsMate provides the best 100% Free Online Web Tools on the Internet that can be useful for your daily online activities.' },
        { hid: 'og:type', property: 'og:type', content: 'website' },
        { hid: 'og:image', property: 'og:image', content: `${process.env.BASE_URL}/assets/images/webtoolsmate-og-image.png` },
        { hid: 'og:url', property: 'og:url', content: `${process.env.BASE_URL}` },
        { hid: 'og:locale', property: 'og:locale', content: 'en_US' },
        // Twitter meta settings
        { hid: 'twitter:site', name: 'twitter:description', content: '@webtoolsmate' },
        { hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' },
        { hid: 'twitter:url', name: 'twitter:url', content: `${process.env.BASE_URL}` },
        { hid: 'twitter:creator', name: 'twitter:creator', content: `${this.$config.siteName}` },
        { hid: 'twitter:title', name: 'twitter:title', content: 'WebToolsMate: Free Online Web Tools' },
        { hid: 'twitter:description', name: 'twitter:description', content: 'WebToolsMate provides the best 100% Free Online Web Tools on the Internet that can be useful for your daily online activities.' },
        { hid: 'twitter:image', name: 'twitter:image', content: `${process.env.BASE_URL}/assets/images/webtoolsmate-og-image.png` },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `${process.env.BASE_URL}${this.$route.path}`
        }
      ]
    }
  },
  data() {
    return {
      categories: [],
      popular_tools: []
    }
  },
  async asyncData({ $axios, params, error, redirect }) {
    try {

      const response = await $axios.$get(`/tools/categories`);

      return { categories: response.categories, popular_tools: response.popular_tools };

    } catch (e) {
      if (e.response.status === 404) {
        error({ statusCode: 404 });
      } else if (e.response.status === 401) {
        redirect('/login');
      } else {
        error({ statusCode: 500 });
      }
    }
  }
}
